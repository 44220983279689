import { render, staticRenderFns } from "./loanForm.vue?vue&type=template&id=3cb535f1&scoped=true"
import script from "./loanForm.vue?vue&type=script&lang=js"
export * from "./loanForm.vue?vue&type=script&lang=js"
import style0 from "./loanForm.vue?vue&type=style&index=0&id=3cb535f1&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb535f1",
  null
  
)

export default component.exports