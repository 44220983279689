<template>
	<div>
		<a-descriptions bordered :title="$t('table.applyInfo.financialLabel.loanType')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.loanType')">
				<a-radio-group v-model="loanInfoForm.loanType" @change="setLoanType">
					<a-radio v-for="loan in loanType" :key="loan.value" :value="loan.value">
						{{ $t(`table.applyInfo.financialLabel.loanMap.${loan.value}`) }}
					</a-radio>
				</a-radio-group>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered size="small" class="loan-info-form">
			<template slot="title">
				<span>{{ $t('table.applyInfo.tabsMenu.loanApply') }}</span>
				<span v-if="beingRevised">
					<a-button @click="saveApplyConfirmLoanInfo" type="primary" style="margin-left: 20px">{{ $t('operate.save') }}</a-button>
					<a-button @click="setBeingRevised" type="primary" style="margin-left: 20px">{{ $t('operate.cancel') }}</a-button>
				</span>
			</template>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.loanFees')">
				<a-input-number v-model="loanInfoForm.applyAmount" :min="0" :precision="0" @change="applyApplyMountTryCalculate"></a-input-number>
			</a-descriptions-item>
			<a-descriptions-item
				:label="
					$t(
						`table.applyInfo.loanApplyLabel.${
							loanType[loanInfoForm.loanType - 5].aliasItem.includes(descriptionsItem) ? descriptionsItem + 'Alias' : descriptionsItem
						}`
					)
				"
				v-for="descriptionsItem in loanType[loanInfoForm.loanType - 5].descriptionsItem"
				:key="descriptionsItem"
			>
				<a-input-number
					v-model="loanInfoForm[descriptionsItem]"
					:min="0"
					:precision="0"
					@focus="inputFocus(descriptionsItem)"
					@change="applyTryCalculate"
				></a-input-number>
				<template v-if="descriptionsItem === 'gpsMonthAmount'">
					<div>Deduct the first month tracking</div>
					<a-radio-group v-model="loanInfoForm.firstMonthDeduce" @change="applyOtherTryCalculate">
						<a-radio :value="1"> Yes </a-radio>
						<a-radio :value="0"> No </a-radio>
					</a-radio-group>
				</template>
			</a-descriptions-item>
			<a-descriptions-item :label="reductions.name" v-for="reductions in loanInfoForm.reductions" :key="reductions.name">
				<a-input-number v-model="reductions.amount" :min="0" :precision="0" @change="applyOtherTryCalculate"></a-input-number>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.payable')">
				<a-input-number v-model="loanInfoForm.amountPayable" :precision="0" @change="applyNetMountTryCalculate"></a-input-number>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.MonthlyInterest')">
				<a-select v-model="loanInfoForm.interestRate" @change="applyOtherTryCalculate">
					<a-select-option :value="item" v-for="item in loanType[loanInfoForm.loanType - 5].interestRateOptions" :key="item"
						>{{ (item * 100).toFixed(4) }}%</a-select-option
					>
				</a-select>
				<div style="margin-top: 5px">
					<a-input-number
						style="width: 100px"
						v-model="custRate"
						addonAfter="Add"
						:formatter="(value) => `${value ? value + '%' : ''}`"
						:parser="(value) => value.replace('%', '')"
					/>
					<a-button type="primary" style="margin-left: 10px" @click="addCustRate"> {{ $t('page.add') }} </a-button>
				</div>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.term')">
				<a-select v-model="loanInfoForm.installmentsNumbers" @change="applyOtherTryCalculate">
					<a-select-option :value="item" v-for="item in loanType[loanInfoForm.loanType - 5].installmentsOptions" :key="item">{{
						item
					}}</a-select-option>
				</a-select>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.firstMonthRepayment')">
				<a-statistic :precision="2" :value="loanInfoForm.firstMonthRepay" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.lastRepayment')">
				<a-statistic :precision="2" :value="loanInfoForm.otherMonthRepay" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.reductions')">
				<a-button type="primary" @click="addNewDeduction = true"> {{ $t('page.add') }} </a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-modal v-drag-modal title="Add New Deduction" v-model="addNewDeduction" @ok="saveNewDeduction">
			<a-descriptions bordered size="small">
				<a-descriptions-item v-for="(reductions, index) in newDeductionList" :key="index" :span="3">
					<template slot="label">
						<a-input v-model="reductions.name"> <a-icon slot="addonBefore" type="delete" @click="deleteDeduction(index)" /></a-input>
					</template>
					<a-input-number v-model="reductions.amount" :precision="0"> </a-input-number>
				</a-descriptions-item>
			</a-descriptions>
			<a-button type="primary" block @click="addDeduction" :disabled="newDeductionList.length > 9"> {{ $t('page.add') }} </a-button>
		</a-modal>
	</div>
</template>
<script>
import { apiEmergencyApplyTryCalculate, apiEmergencyApplyConfirmLoanInfo } from '@/api/emergencyLoan'
export default {
	data() {
		return {
			addNewDeduction: false,
			disabledAdd: false,
			isReverse: false,
			loanInfoForm: {
				loanType: 5,
				applyAmount: null,
				disposePercent: 3,
				insurancePercent: 3,
				loanProcessingFee: null,
				exciseDuty: null,
				creditLifeInsuranceFee: null,
				lessLoanBalance: 0,
				crbAmount: 500,
				transferAmount: 2100,
				gpsInstallAmount: 2500,
				gpsMonthAmount: 2500,
				mortgageRegistrationAmount: 2500,
				firstMonthDeduce: 1,
				interestRate: 0.1,
				installmentsNumbers: 3,
				insurancePremium: 0, //保险费用
				engineParticulars: 0, //发动机费用
				incharge: 0, //维护费用
				amountPayable: null,
				firstMonthRepay: 0,
				otherMonthRepay: 0,
				reductions: [],
				maintenanceFee: null, //Loan Maintenance fess
				maintenanceFeeInstallment: 500, //Loan Maintenance fess
				applicationFee: 500, //Loan Application Fee
				kraEntryFee: 1700 //KRA Entry Fee
			},
			currentInput: null,
			newDeductionList: [],
			custRate: null,
			loanType: [
				{
					name: 'Emergency Loan',
					value: 5,
					interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08, 0.1],
					installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
					descriptionsItem: ['loanProcessingFee', 'mortgageRegistrationAmount', 'exciseDuty'],
					defaultsValue: {
						interestRate: 0.1,
						installmentsNumbers: 3,
						mortgageRegistrationAmount: 2500
					},
					defScale: {
						exciseDuty: '15%'
					},
					aliasItem: [],
					prefixType: 'EBC '
				},
				{
					name: 'Cash Pap',
					value: 6,
					interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08, 0.1],
					installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
					descriptionsItem: ['loanProcessingFee', 'creditLifeInsuranceFee', 'mortgageRegistrationAmount', 'applicationFee', 'exciseDuty'],
					defaultsValue: {
						interestRate: 0.06,
						installmentsNumbers: 3,
						creditLifeInsuranceFee: 0,
						applicationFee: 1600,
						mortgageRegistrationAmount: 2500
					},
					defScale: {
						exciseDuty: '15%'
					},
					aliasItem: [],
					prefixType: 'CBC '
				}
			]
		}
	},
	props: ['loanInfo', 'beingRevised'],
	watch: {
		loanInfo() {
			this.loanInfoForm = this.loanInfo
		}
	},
	created() {
		this.$nextTick(() => {
			if (this.loanInfo.id) {
				this.loanInfoForm = this.loanInfo
				this.newDeductionList = this.loanInfo.reductions
			}
		})
	},
	methods: {
		setLoanType(val) {
			this.loanInfoForm.interestRate = this.loanType[val.target.value - 5].defaultsValue.interestRate
			this.loanInfoForm.installmentsNumbers = this.loanType[val.target.value - 5].defaultsValue.installmentsNumbers
			this.loanInfoForm.applicationFee = this.loanType[val.target.value - 5].defaultsValue.applicationFee
			this.applyOtherTryCalculate()
		},
		saveApplyConfirmLoanInfo() {
			this.loanInfoForm.disposePercent = this.loanInfoForm.disposePercent * 0.01
			this.loanInfoForm.insurancePercent = this.loanInfoForm.insurancePercent * 0.01
			apiEmergencyApplyConfirmLoanInfo({
				...this.loanInfoForm
			}).then(() => {
				this.setBeingRevised()
			})
		},
		setBeingRevised() {
			this.$emit('setDataChange', {
				key: 'beingRevised',
				value: false
			})
		},
		saveNewDeduction() {
			this.loanInfoForm.reductions = [...this.newDeductionList]
			this.applyOtherTryCalculate()
			this.addNewDeduction = false
		},
		deleteDeduction(index) {
			this.newDeductionList.splice(index, 1)
		},
		addCustRate() {
			if (!this.loanType[this.loanInfoForm.loanType - 5].interestRateOptions.includes(Number(this.custRate) / 100)) {
				this.loanType[this.loanInfoForm.loanType - 5].interestRateOptions.push(Number(this.custRate) / 100)
			}
			this.loanInfoForm.interestRate = Number(this.custRate) / 100
			this.applyOtherTryCalculate()
		},
		addDeduction() {
			if (this.newDeductionList.length < 10) {
				this.newDeductionList.push({
					name: '',
					amount: 0
				})
			}
		},
		inputFocus(value) {
			this.currentInput = value
		},
		applyTryCalculate(value) {
			if (this.currentInput === 'loanProcessingFee') {
				this.loanInfoForm.loanProcessingFee = value
			}
			if (this.currentInput === 'exciseDuty') {
				this.loanInfoForm.exciseDuty = value
			}
			if (this.currentInput === 'creditLifeInsuranceFee') {
				this.loanInfoForm.creditLifeInsuranceFee = value
			}
			if (this.currentInput === 'maintenanceFee') {
				this.loanInfoForm.maintenanceFee = value
			}
			apiEmergencyApplyTryCalculate({
				...this.loanInfoForm
			}).then((res) => {
				this.loanInfoForm = res
				this.$emit('setDataChange', {
					key: 'emergencyFormInfo.loanInfo',
					value: res
				})
				this.$emit('setDataChange', {
					key: 'loanInfo',
					value: res
				})
			})
		},
		applyOtherTryCalculate() {
			apiEmergencyApplyTryCalculate({
				...this.loanInfoForm
			}).then((res) => {
				this.loanInfoForm = res
				this.$emit('setDataChange', {
					key: 'emergencyFormInfo.loanInfo',
					value: res
				})
				this.$emit('setDataChange', {
					key: 'loanInfo',
					value: res
				})
			})
		},
		applyApplyMountTryCalculate(value) {
			this.loanInfoForm.applyAmount = value
			this.loanInfoForm.loanProcessingFee = null
			this.loanInfoForm.exciseDuty = null
			this.loanInfoForm.creditLifeInsuranceFee = null
			this.loanInfoForm.maintenanceFee = null
			apiEmergencyApplyTryCalculate({
				...this.loanInfoForm
			}).then((res) => {
				this.loanInfoForm = res
				this.$emit('setDataChange', {
					key: 'emergencyFormInfo.loanInfo',
					value: res
				})
				this.$emit('setDataChange', {
					key: 'loanInfo',
					value: res
				})
			})
		},
		applyNetMountTryCalculate(value) {
			this.loanInfoForm.applyAmount = null
			this.loanInfoForm.loanProcessingFee = null
			this.loanInfoForm.exciseDuty = null
			this.loanInfoForm.creditLifeInsuranceFee = null
			this.loanInfoForm.maintenanceFee = null
			this.loanInfoForm.amountPayable = value
			apiEmergencyApplyTryCalculate({
				...this.loanInfoForm
			}).then((res) => {
				this.loanInfoForm = res
				this.$emit('setDataChange', {
					key: 'emergencyFormInfo.loanInfo',
					value: res
				})
				this.$emit('setDataChange', {
					key: 'loanInfo',
					value: res
				})
			})
		}
	}
}
</script>
<style scoped lang="less">
/deep/ .loan-info-form {
	.ant-input-number-handler-wrap {
		display: none !important;
	}
}
.ant-row.ant-form-item {
	margin-bottom: 10px;
}
.ant-input-number,
.ant-select {
	width: 200px;
}
</style>
